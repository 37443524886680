.map-container {
	width: 100%;
	height: 500px;
	position: relative;
}

.leaflet-container {
	height: 100%;
}

.marker-cluster-custom {
	background: #3A8B7E;
	border: 1px solid #FFF;
	border-radius: 50%;
	color: #FFF;
	height: 30px;
	width: 30px;
	line-height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.leaflet-interactive {
	outline: 0;
}

.leaflet-popup-content-wrapper {
	box-shadow: none;
}

.leaflet-popup-content p {
	margin: 0;
}

.leaflet-popup-content a {
	color: #3A8B7E;
}